<template>
  <div>
    <RightSideBar toggleClass="top-50 setting-toggle" v-if="false">
      <i class="ri-sound-module-fill font-size-18 text-primary" slot="icon" />
      <iq-card class="shadow-none">
        <template v-slot:headerTitle>
          <h4>{{ $t("customizer.colorCustomizer") }}</h4>
        </template>
        <template v-slot:headerAction>
          <b-button variant="primary" size="sm" @click="reset">{{
            $t("customizer.reset")
          }}</b-button>
        </template>
        <template v-slot:body>
          <b-row>
            <b-col cols="12" class="justify-content-between">
              <h4 class="text-left mb-2">{{ $t("customizer.themeColor") }}</h4>
              <div class="text-center">
                <div
                  v-for="(item, index) in colors"
                  :key="index"
                  class="d-inline-flex justify-content-between"
                >
                  <div
                    :style="`background: ${item.primary};border-radius: 50%;`"
                    @click="changeColor(item)"
                    class="p-3 mx-1"
                  ></div>
                </div>
              </div>
            </b-col>
            <div class="border mt-4 mb-4 w-100" />
            <b-col cols="12" class="justify-content-between">
              <h4 class="text-left mb-2">{{ $t("customizer.colorMode") }}</h4>
              <div class="text-center d-flex justify-content-around">
                <img
                  :src="
                    require('../../assets/images/customizer/customizer-01.png')
                  "
                  alt="light"
                  @click="themeMode(false)"
                  class="img-fluid"
                  style="height: 150px; border: 2px solid var(--iq-primary)"
                />
                <img
                  :src="
                    require('../../assets/images/customizer/customizer-03.png')
                  "
                  alt="dark"
                  @click="themeMode(true)"
                  class="img-fluid"
                  style="height: 150px; border: 2px solid var(--iq-primary)"
                />
              </div>
            </b-col>
            <!--            <div class="border mt-4 mb-4 w-100" v-if="!notShowSidebarSetting.includes($route.name)" />-->
            <!--            <b-col cols="12" class="justify-content-between" v-if="!notShowSidebarSetting.includes($route.name)">-->
            <!--              <h4 class="text-left mb-2">{{ $t('customizer.sidebar') }}</h4>-->
            <!--            </b-col>-->
            <!--            <b-col cols="12" class="justify-content-between d-flex" v-if="!notShowMiniSidebar.includes($route.name)">-->
            <!--              <label>{{ $t('customizer.sidebarMini')}}</label>-->
            <!--              <b-form-checkbox class="custom-switch-color" color="primary" v-model="mini" @change="miniSidebar" name="check-button" switch inline>-->
            <!--              </b-form-checkbox>-->
            <!--            </b-col>-->
            <div class="border mt-4 mb-4 w-100" />
            <b-col cols="12" class="justify-content-between">
              <h4 class="text-left mb-2">{{ $t("customizer.rtlMode") }}</h4>
              <div class="text-center d-flex justify-content-around">
                <img
                  :src="require('../../assets/images/customizer/ltr-img.png')"
                  alt="ltr"
                  @click="rtlChange(false)"
                  class="img-fluid"
                  style="height: 150px; border: 2px solid var(--iq-primary)"
                />
                <img
                  :src="require('../../assets/images/customizer/rtl-img.png')"
                  alt="rtl"
                  @click="rtlChange(true)"
                  class="img-fluid"
                  style="height: 150px; border: 2px solid var(--iq-primary)"
                />
              </div>
            </b-col>
            <div class="border mt-4 mb-4 w-100" />
            <b-col cols="12" class="justify-content-between">
              <h4 class="text-left mb-2">
                {{ $t("customizer.routeAnimation") }}
              </h4>
              <div class="text-center d-flex">
                <b-form-radio-group
                  @change="animateChange"
                  id="radio-group-1"
                  v-model="animated"
                  :options="animateClass"
                  name="radio-options"
                ></b-form-radio-group>
              </div>
            </b-col>
          </b-row>
        </template>
      </iq-card>
    </RightSideBar>
    <div class="card" :class="foldStatus ? 'in-card':''" id="imgBox">
      <i class="el-icon-arrow-right" v-if="!foldStatus" @click="setFold(true)"></i>
      <i class="el-icon-arrow-left" v-else @click="setFold(false)"></i>
      <img
        src="../../assets/images/home/calculate.png"
        id="boxImg"
        alt=""
        @click="calculator"
        :style="style"
      />
    </div>
    <!-- <div :class="moveStatus ? 'max-box':''" id="max-box"> -->
      <div class="calculator-box" v-if="showCalculator">
        <div class="top-box">
          <button @mousedown="downMouse" @mouseup="upMouse" >按住拖拽</button>
        </div>
        <img src="../../assets/images/home/ct-icon.png" @click="quit" alt="">
        <iframe
          class="labourcalculator-iframe"
          :src="`https://cal.ai-hrcompliance.com/?cjx=${tokenStr}`"
          frameborder="0"
        ></iframe>
      </div>
    <!-- </div> -->
  </div>
</template>
<script>
import darkLoader from "../../assets/images/darkMode/dark-logo.gif";
// import loader from '../../assets/images/loader.gif'

import loader from "../../assets/images/home/iconLogoin.png";
import RightSideBar from "../../components/core/rightsidebar/RightSideBar";
import { mapActions, mapGetters } from "vuex";
import { getToken } from '../../Utils/token';
import Tdrag from "@/Utils/Tdrag";
import { getCounterToken } from "@/FackApi/api/index";
export default {
  name: "Customizer",
  components: {
    RightSideBar,
  },
  computed: {
    ...mapGetters({
      darkMode: "Setting/darkModeState",
      colors: "Setting/colorState",
    }),
  },
  mounted() {
    this.updateRadio();
    this.getCounterTokenInfo();
    // 官网点击计算器跳转过来  显示计算器
    if (localStorage.getItem('fromLabel') == '计算器') {
      this.showCalculator = true;
      localStorage.removeItem('fromLabel');
    }
  },
  data() {
    return {
      candrag: false,
      x0: 0,
      y0: 0,
      x1: 0,
      y1: 0,
      style: null,

      animated: { enter: "fadeInUp", exit: "fadeOut" },
      animateClass: [
        { value: { enter: "zoomIn", exit: "zoomOut" }, text: "Zoom" },
        { value: { enter: "fadeInUp", exit: "fadeOut" }, text: "Fade" },
        {
          value: { enter: "slideInLeft", exit: "slideOutRight" },
          text: "Slide",
        },
        {
          value: { enter: "rotateInDownLeft", exit: "rotateOutDownLeft" },
          text: "Roll",
        },
      ],
      logo: loader,
      rtl: false,
      dark: false,
      mini: false,
      notShowMiniSidebar: ["dashboard.home-1"],
      notShowSidebarSetting: [
        "dashboard.home-1",
        "dashboard.home-3",
        "dashboard.home-4",
      ],
      showCalculator: false,
      tokenStr: '',
      moveStatus: false,
      foldStatus: false,
    };
  },
  methods: {
    themeMode(mode) {
      this.dark = mode;
      this.modeChange({ rtl: this.rtl, dark: mode });
      if (mode) {
        this.logo = darkLoader;
      } else {
        this.logo = loader;
      }
      this.$emit("onLogo", this.logo);
    },
    getCounterTokenInfo() {
      getCounterToken().then((res) => {
        // console.log(res);
        if (res.code == 0) {
          this.tokenStr = res.data;
        }
      })
    },
    changeColor(code) {
      // console.log(code);
      document.documentElement.style.setProperty("--iq-primary", code.primary);
      document.documentElement.style.setProperty(
        "--iq-primary-light",
        code.primaryLight
      );
      if (this.darkMode) {
        document.documentElement.style.setProperty(
          "--iq-bg-dark-color",
          code.bodyBgDark
        );
      } else {
        document.documentElement.style.setProperty(
          "--iq-bg-light-color",
          code.bodyBgLight
        );
      }
    },
    reset() {
      this.changeColor({
        primary: "#6475c7",
        primaryLight: "#7abbf3",
        bodyBgLight: "#eaf5ff",
        bodyBgDark: "#1d203f",
      });
      this.animated = { enter: "zoomIn", exit: "zoomOut" };
      this.themeMode(false);
      this.rtlChange(false);
    },
    rtlChange(mode) {
      this.rtl = mode;
      this.modeChange({ rtl: mode, dark: this.darkMode });
      if (this.darkMode) {
        this.logo = darkLoader;
      } else {
        this.logo = loader;
      }
    },
    updateRadio() {
      this.mini = this.$store.getters["Setting/miniSidebarState"];
    },
    async miniSidebar() {
      this.$emit("toggle");
      await this.$nextTick();
      this.updateRadio();
    },
    async animateChange() {
      await this.$nextTick();
      this.$emit("animationChange", this.animated);
    },
    ...mapActions({
      modeChange: "Setting/layoutModeAction",
    }),
    // start(e) {
    //   // 鼠标左键点击
    //   if (e.button === 0) {
    //     this.candrag = true;
    //     // 记录鼠标指针位置
    //     this.x0 = e.clientx;
    //     this.y0 = e.clienty;
    //   }
    // },
    // stop(e) {
    //   this.candrag = false;
    // },
    // move(e) {
    //   if (this.candrag === true) {
    //     this.x1 = e.clientx;
    //     this.y1 = e.clientx;
    //     const x = this.x1 - this.x0;
    //     const y = this.y1 - this.y0;
    //     const img = document.querySelector("boxImg");
    //     this.style = `left:${img.offsetleft + x}px;top:${img.offsettop + y}px`;
    //     this.x0 = this.x1;
    //     this.y0 = this.y1;
    //   }
    // },
    calculator() {
      this.showCalculator = true;
    },
    quit() {
      this.showCalculator = false;
    },
    setFold(status) {
      this.foldStatus = status;
    },
    // 鼠标按下
    downMouse() {
      // this.moveStatus = true;
      this.$nextTick(() => {
        $(".calculator-box").Tdrag(
          // {
          //   scope:"#max-box",
          // }
        );
      });
    },
    // 鼠标松开
    upMouse() {
      $.disable_cloose()
    },
  },
};
</script>
<style lang="less" scoped>
.card {
  position: fixed !important;
  right: 0;
  bottom: 3%;
  z-index: 1000 !important;
  width: 158px;
  height: 148px;
  background: rgba(0, 0, 0, 0) !important;
  i {
    position: absolute;
    top: 20px;
    left: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 24px;
    text-align: center;
    color: #5a72d9;
    background-color: #e9f5ff;
    border-radius: 15px;
  }
}
.in-card {
  right: -130px;
}
  .calculator-box {
    position: fixed !important;
    top: 180px;
    right: 140px;
    width: 380px;
    height: 600px;
    border-radius: 18px;
    padding: 40px 10px 14px 10px;
    background-color: #fff;
    box-shadow: 0px 0px 20px 0px rgba(71,123,203,0.8000);
    z-index: 999;
    .labourcalculator-iframe {
      width: 100%;
      height: 100%;
    }
    .top-box {
      position: absolute;
      top: 0;
      left: 0;
      width: 380px;
      border-radius: 18px 18px 0 0;
      overflow: hidden;
      button {
        width: 100%;
        height: 40px;
        font-size: 18px;
        background-color: #6a87d2;
        color: #fff;
        border: none;
      }
    }
    img {
      position: absolute;
      top: -20px;
      right: -20px;
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
  }
</style>
