<template>
  <b-collapse
    tag="ul"
    :class="className"
    :visible="open"
    :id="idName"
    :accordion="accordianName"
  >
    <li
      v-for="(item, index) in items"
      :key="index"
      :class="
        !hideListMenuTitle
          ? 'p-0'
          : item.is_heading
          ? 'iq-menu-title'
          : activeLink(item) && item.children
          ? 'active'
          : activeLink(item)
          ? 'active'
          : ''
      "
    >
      <i v-if="item.is_heading && hideListMenuTitle" class="ri-subtract-line" />
      <span v-if="item.is_heading && hideListMenuTitle">{{
        $t(item.name)
      }}</span>
      <router-link
        :to="item.link"
        v-if="!item.is_heading"
        :class="`iq-waves-effect ${
          activeLink(item) && item.children
            ? 'active'
            : activeLink(item)
            ? 'active'
            : ''
        }`"
        v-b-toggle="item.name"
      >
        <i :class="item.icon" v-if="item.is_icon_class" />
        <template v-else v-html="item.icon"> </template>
        <span>{{ $t(item.name) }}</span>
        <i v-if="item.children" class="ri-arrow-right-s-line iq-arrow-right" />
        <small
          v-html="item.append"
          v-if="hideListMenuTitle"
          :class="item.append_class"
        />
        <p class="right-p" v-if="selectShow(item)">敬请期待</p>
      </router-link>
      <List
        v-if="item.children"
        :items="item.children"
        :sidebarGroupTitle="hideListMenuTitle"
        :open="
          item.link.name !== '' && activeLink(item) && item.children
            ? true
            : !!(item.link.name !== '' && activeLink(item))
        "
        :idName="item.name"
        :accordianName="`sidebar-accordion-${item.class_name}`"
        :className="`iq-submenu ${item.class_name}`"
      />
    </li>
  </b-collapse>
</template>
<script>
import List from "./CollapseMenu";
import { core } from "../../../config/pluginInit";
export default {
  name: "List",
  props: {
    items: Array,
    className: { type: String, default: "iq-menu" },
    open: { type: Boolean, default: false },
    idName: { type: String, default: "sidebar" },
    accordianName: { type: String, default: "sidebar" },
    sidebarGroupTitle: { type: Boolean, default: true },
  },
  data() {
    return {
      showStatus: false,
    }
  },
  components: {
    List,
  },
  computed: {
    hideListMenuTitle() {
      return this.sidebarGroupTitle;
    },
  },
  mounted() {
  },
  methods: {
    activeLink(item) {
      return core.getActiveLink(item, this.$route.name);
    },
    // 开发中模块提示语
    selectShow(row) {
      // console.log(row);
      if (
        row.name == 'sidebar.socialApp' 
        // ||
        // row.name == 'sidebar.editable' 
        // ||
        // row.name == 'sidebar.basicTable' 
        // ||
        // row.name == 'sidebar.servereport' 
        // ||
        // row.name == 'sidebar.servereport'
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style scoped>
  .right-p {
    /* position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%); */
    color: #0094ff;
    margin-left: 40px;
  }
  /* .iq-waves-effect {
    position: relative;
  } */
</style>
